<template>
  <div style=" width: 16px;" class="mr-1">
    <Button
      style="width: 16px; overflow: visible"
      type="button"
      icon="pi pi-filter"
      :class="{ 'fil-btn': valueFrom || valueTo }"
      class="p-button-sm p-button-text p-0"
      @click="toggle"
    />

    <OverlayPanel ref="filters" :showCloseIcon="true">
      <div>
        <small class="text-center block mb-2"
          >Оба поля обязательны к заполнению</small
        >
        <div style="width: 300px" class="p-inputtext-sm p-inputgroup m-1">
          <InputMask
            type="input"
            @keyup.enter="showValue"
            @input="isSearcDisabled"
            v-model="vFrom"
            placeholder="дд.мм.гггг"
            mask="99.99.9999"
            slotChar="дд.мм.гггг"
          />

          <InputMask
            type="input"
            @keyup.enter="showValue"
            @input="isSearcDisabled"
            v-model="vTo"
            placeholder="дд.мм.гггг"
            mask="99.99.9999"
            slotChar="дд.мм.гггг"
          />

          <Button
            @click="showValue"
            icon="pi pi-search"
            class="p-button"
            v-tooltip="'Поиск'"
          />
          <Button
            @click="removeFilter"
            icon="pi pi-times"
            class="p-button"
            v-tooltip="'Сбросить фильтр'"
          />
          <!-- :disabled="isSearcDisables" -->
        </div>
      </div>
    </OverlayPanel>
  </div>
</template>
  
  <script>
export default {
  name: "FilterFromTo",
  props: {
    placeholderName: {
      type: String,
      required: true,
    },
    valueFrom: {
      type: String,
    },
    valueTo: {
      type: String,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isSearcDisables: true,
      vTo: this.valueTo,
      vFrom: this.valueFrom,
    };
  },
  methods: {
    removeFilter() {
      this.$emit("update:valueFrom", "");
      this.$emit("update:valueTo", "");
      this.vTo = "";
      this.vFrom = "";
    },
    isSearcDisabled() {
  
      if (this.vTo && this.vFrom) {
        this.isSearcDisables = false;
      } else {
        this.isSearcDisables = true;
      }
      if (!this.vTo && !this.vFrom) {
        this.$emit("update:valueFrom", "");
        this.$emit("update:valueTo", "");
      }
    },
    showValue() {
      this.$emit(
        "update:valueFrom",
        this.vFrom && this.vTo ? this.vFrom : null
      );
      this.$emit("update:valueTo", this.vFrom && this.vTo ? this.vTo : null);
      // this.toggle();
    },
    toggle(event) {
      this.$refs.filters.toggle(event);
    },
  },
  created() {
    this.value ? (this.isSearcDisables = false) : (this.isSearcDisables = true);
  },
  computed: {
    valueFrom: {
      get() {
        return this.valueFrom;
      },
      set(value) {
        this.$emit("update:valueFrom", value);
      },
    },
    valueTo: {
      get() {
        return this.valueTo;
      },
      set(value) {
        this.$emit("update:valueTo", value);
      },
    },
  },
  watch: {
    valueTrn(value) {
      value.length === 0 ? (this.value = null) : null;
    },
  },

  mounted() {
    // this.isSearcDisabled();
  },
};
</script>
  
  <style lang="scss"> 
</style>