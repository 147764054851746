<template>
  <Button
    @click="clickBtn"
    class="p-button-sm"
    :class="btnClass"
    icon="pi"
    label="Выставить счёт"
    :disabled="!isDisabled"
  >
    <slot></slot
  ></Button>
  <Dialog
    @show="watchValue(sum)"
    v-model:visible="visible"
    header="Формирование счета на оплату"
    :draggable="false"
    modal
    ref="di"
    class="bill-dialog"
    style="z-index: 10002 !important"
    :style="{ width: '460px' }"
    :breakpoints="{ '960px': '360px', '641px': '100vw' }"
  >
    <div class="px-2 mt-4 bill-wrapper">
      <div class="flex-auto mb-2">
        <label class="font-bold block mb-2"> Компания </label>
       <h5>{{ companyName }}</h5>
      </div>
      <hr>
      <div class="flex-auto mt-4">
        <label for="integeronly" class="font-bold block mb-2">
          Введите сумму в рублях
        </label>
        <InputNumber
          suffix=" P"
          autofocus
          class="w-full"
          :class="{ 'p-invalid': disabledBtn }"
          v-model="sum"
          ref="sumka"
          inputId="integeronly"
          :min="0"
          @input="changeDisabled($event)"
          @keypress.enter="getBill"
        />
      </div>
      <div class="flex-auto mt-4">
        <Button
          :loading="loading"
          @click="getBill"
          :disabled="disabledBtn"
          class="w-full"
          label="Сформировать"
        />
      </div>
    </div>
  </Dialog>
</template>

<script>
import { getBill } from "@/api/bill";
export default {
  name: "CreateBill",
  props: {
    isDisabled: {
      type: Boolean,
      requaried: true,
    },
    companyName: {
      type: String,
      requaried: true,
    },
    idCompany: {
      type: Number,
      requaried: true,
    },
    btnClass: {
      type: Array,
      requaried: false,
    },
  },
  data() {
    return {
      visible: false,
      loading: false,
      sum: 0,
      disabledBtn: !this.sum,
    };
  },
  methods: {
    clickBtn() {
      this.visible = !this.visible;
    },
    getBill() {
      if (!this.disabledBtn) {
        this.loading = true;
        getBill(this, this.idCompany);
      } else {
      }
    },
    watchValue(val) {
      if (val > 0) {
        this.disabledBtn = false;
      } else {
        this.disabledBtn = true;
      }
    },
    changeDisabled(e) {
      this.sum = e.value;
      this.watchValue(e.value);
    },
  },
  mounted() {
    this.$refs.di.maskClass.push("bill-mask");
  },
};
</script>

<style lang="scss">
.bill-wrapper {
  button {
    display: flex;
    justify-content: center;
    span.p-button-label {
      width: auto !important;
      flex: 1 1 100% !important;
    }
  }
}
.bill-dialog {
  @media (max-width: 641px) {
    max-height: inherit !important;
    height: inherit !important;
    display: flex;
    justify-content: center;
    background: #fff;
    border-radius: 0 !important;
  }
}
.p-dialog-mask.p-component-overlay.p-component-overlay-enter.bill-mask {
  z-index: 10006 !important;
}
</style>