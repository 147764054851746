// report / get_ballances

import api from "@/api/index";
import {
    days
} from "@/plugins/dayjs/index";
import {
    timeFormater
} from "@/handlers/timeFormater";
import {
    setEmoji
} from "@/handlers/emoji";

export function getBlnsReport(that) {
    let url = "report/get_ballances?report";
    that.loading = true; 
    api
        .get(url, {
            responseType: "blob",
            params: {
                file_type: 'xlsx',
                contract_date_gte: that.filters.contract_date.from && that.filters.contract_date.to ?
                    days(that.filters.contract_date.from, "server-start") : null,
                contract_date_lte: that.filters.contract_date.from && that.filters.contract_date.to ?
                    days(that.filters.contract_date.to, "server-end") : null,
                name: that.nameCompany,
                rows: that.rows,
                page: that.page,
                company: that.companys,
                sort: that.sort,
                desc: that.desc,
                ballance_from: that.filters.ballance.from ?
                    that.filters.ballance.from : null,
                ballance_to: that.filters.ballance.to ? that.filters.ballance.to : null,
                contract_num: that.filters.contract_num ?
                    that.filters.contract_num : null,
                limit_from: that.filters.limit.from ? that.filters.limit.from : null,
                limit_to: that.filters.limit.to ? that.filters.limit.to : null,
                comment: that.filters.comment ? that.filters.comment : null,
                inn: that.filters.inn ? that.filters.inn : null,
                manager: that.filters.manager ? that.filters.manager : null,
                status: that.filters.status !== null ? that.filters.status : "",
            },
        })
        .then((r) => {
            console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
            const href = URL.createObjectURL(r.data);
            const format = "xlsx";
            const nameFile =
                "Отчет по компаниям " +
                timeFormater(that.dateStartTrn) +
                " - " +
                timeFormater(that.dateEndTrn) +
                "." +
                format;
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", nameFile); //or any other extension
            document.body.appendChild(link);
            link.click();
            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        })
        .catch((err) => {
            console.log(err);
            console.log(err.request);
            let statusCode = err.request.status;
            let statusText = err.request.statusText;
            console.error(setEmoji("error") + statusCode + " " + statusText);
            switch (statusCode) {
                case 0:
                    that.$toast.add({
                        severity: "warn",
                        summary: `${setEmoji("error")} Нет связи с сервером`,
                        detail: "Попробуйте позднее!",
                        life: 5000,
                    });
                    break;
                case 403:
                    that.$toast.add({
                        severity: "warn",
                        summary: `${setEmoji("lock")} Время сессии истекло`,
                        detail: "Требуется авторизация",
                        life: 5000,
                    });
                    that.$router.push("/login");
                    break;
                case 404:
                    that.$toast.add({
                        severity: "error",
                        summary: `${setEmoji("error")} Ошибка ${statusCode}`,
                        detail: "Запрос не найден",
                        life: 5000,
                    });
                    break;
                case 406:
                    that.$toast.add({
                        severity: "error",
                        summary: `${setEmoji("error")} Ошибка ${statusCode}`,
                        detail: "Укажите компании",
                        life: 3000,
                    });
                    break;
                default:
                    that.$toast.add({
                        severity: "error",
                        summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
                        detail: "Попробуйте позднее!",
                        life: 5000,
                    });
                    break;
            }
        })
        .finally(() => {
            setTimeout(() => {
                that.loading = false;
                that.flagSearch = false;
            }, 2000);
        });
}