<template>
  <div class="flex flex-column mt-1">
    <div class="flex flex-column flex-auto">
      <div class="balance-wrapper">
        <div class="w-full">
          <div class="surface-card shadow-2 border-round h-full blns-wrapper">
            <div class="table-header p-datatable-header py-0 px-0 bold">
              <div class="grid justify-content-between m-0 align-items-center">
                <div class="p-inputgroup col-4">
                  <InputText
                    @keyup.enter="searchCompany"
                    placeholder="Название компании"
                    v-model.trim="nameCompany"
                    type="search"
                    style="height: 2rem"
                  />
                  <Button
                    @click="searchCompany"
                    icon="pi pi-search"
                    class="p-button-sm"
                    style="width: 2rem; height: 2rem"
                  />
                </div>
                <div class="flex">
                  <div>
                    <Button
                      :disabled="resetDisables"
                      @click="resetFiltres"
                      v-tooltip.right="'Сбросить все фильтры'"
                      iconPos="right"
                      icon="pi pi-filter-slash"
                      class="col-2 sm:mx12 mx-0"
                      style="width: 2rem; height: 2rem"
                    />
                  </div>
                  <div>
                    <Button
                      v-tooltip.left="'Выгрузить в XLS'"
                      type="button"
                      icon="pi pi-file-excel"
                      class="ml-2"
                      label="XLSX"
                      @click="downloadExcel"
                      style="width: 6rem; height: 2rem"
                    />
                  </div>
                  <div>
                    <Button
                      class="mx-2"
                      v-tooltip.left="'Обновить данные'"
                      @click="refreshData"
                      icon="pi pi-refresh"
                      style="width: 2rem; height: 2rem"
                    />
                  </div>
                </div>
              </div>
            </div>
            <DataTable
              v-if="sortView"
              class="p-datatable-sm"
              responsiveLayout="scroll"
              :scrollable="true"
              :resizableColumns="true"
              columnResizeMode="expand"
              :loading="loading"
              sortMode="single"
              filterDisplay="menu"
              :lazy="true"
              :value="ballanceList"
              showGridlines
              stripedRows
              dataKey="id"
              ref="dt"
              breakpoint="1200px"
              selectionMode="multipe"
              @rowSelect="onRowSelect"
              @sort="onSort($event)"
              :rowClass="rowClass"
            >
              <!-- <template class="flex asdasdasdadasd" #header>
      <div class="p-input-icon-left">
        <i class="pi pi-search" />
        <InputText
          class="p-inputtext-filled w-full"
          type="text"
          v-model="searchString"
          placeholder="Поиск по компаниям"
        />
      </div>
    </template> -->
              <Column field="row_number" style="width: 3rem; max-width: 3rem">
                <template #body="slotProps">
                  <div :class="{ 'font-bold': !slotProps.data.row_number }">
                    {{
                      slotProps.data.row_number
                        ? slotProps.data.row_number
                        : "Итог:"
                    }}
                  </div>
                </template>
              </Column>
              <Column field="name" :sortable="true">
                <template #header>
                  <span style="order: 3">Название</span>
                </template>
                <template #body="slotProps">
                  <router-link
                    v-if="slotProps.data.row_number"
                    class="text-black-alpha-90 py-0 name-link"
                    @click="choiceCompany($event, slotProps.data.name)"
                    v-ripple
                    to="/"
                    >{{ slotProps.data.name }}
                    <i class="ml-2 opacity-0 pi pi-external-link text-sm"></i>
                  </router-link>
                </template>
              </Column>

              <Column field="contract_num" :sortable="true">
                <template #header>
                  <FilterSearch
                    :type="input"
                    :dsc="false"
                    v-model="filters.contract_num"
                  />
                  <span style="order: 3">Номер договора</span>
                </template>
                <template #body="slotProps">
                  <div v-if="slotProps.data.row_number">
                    № {{ slotProps.data.contract_num }}
                  </div>
                </template>
              </Column>
              <Column field="contract_date" :sortable="true">
                <template #header>
                  <TheFilterDate
                    :type="input"
                    :dsc="false"
                    v-model:valueFrom="filters.contract_date.from"
                    v-model:valueTo="filters.contract_date.to"
                  ></TheFilterDate>
                  <span style="order: 3">Дата договора</span>
                </template>
                <template #body="slotProps">
                  <div v-if="slotProps.data.row_number">
                    {{
                      slotProps.data.contract_date
                        ? formaterDate(slotProps.data.contract_date, false)
                        : "-"
                    }}
                  </div>
                </template>
              </Column>

              <Column field="ballance" :sortable="true">
                <template #header>
                  <FilterFromTo
                    v-model:valueFrom="filters.ballance.from"
                    v-model:valueTo="filters.ballance.to"
                  />
                  <span style="order: 3">Баланс, Р</span>
                </template>
                <template #body="slotProps">
                  <div
                    v-if="slotProps.data.row_number"
                    style="pointer-events: none"
                    :class="{ 'text-red-500': slotProps.data.ballance < 0 }"
                  >
                    {{ +slotProps.data.ballance }}
                  </div>
                  <div
                    v-else
                    :class="{ 'text-red-500': slotProps.data.ballance_sum < 0 }"
                  >
                    {{ slotProps.data.ballance_sum }}
                  </div>
                </template>
              </Column>
              <Column field="limit" :sortable="true">
                <template #header>
                  <FilterFromTo
                    v-model:valueFrom="filters.limit.from"
                    v-model:valueTo="filters.limit.to"
                  />
                  <span style="order: 3">Порог, Р</span>
                </template>
                <template #body="slotProps">
                  <div v-if="slotProps.data.row_number">
                    {{ slotProps.data.limit }}
                  </div>
                  <div v-else v-tooltip.top="'Средний порог'">
                    {{ slotProps.data.limit_avg }}
                  </div>
                </template>
              </Column>
              <Column
                :class="stockClass"
                field="status"
                style="width: 7.6rem; max-width: 7.5rem"
                :sortable="true"
              >
                <template #header>
                  <TheSelectFilters
                    :items="[
                      { name: 'Активна', value: 1 },
                      { name: 'Заблокирована', value: 2 },
                    ]"
                    :name="'name'"
                    v-model="filters.status"
                  />
                  <span style="order: 3">Статус</span>
                </template>
                <template #body="slotProps">
                  <div
                    class="m-auto pi"
                    style="text-align: center; font-weight: 700"
                    v-tooltip.right="
                      slotProps.data.status != 1 ? 'Заблокирована' : 'Активна'
                    "
                    :class="stockClass(slotProps.data)"
                  ></div>
                </template>
              </Column>
              <Column field="sum_last" header="Объем за прошлый месяц, л">
                <template #body="slotProps">
                  <div v-if="slotProps.data.row_number">
                    {{ slotProps.data.sum_last ? slotProps.data.sum_last : 0 }}
                  </div>
                  <div v-else>
                    {{ slotProps.data.amount_by_last_month }}
                  </div>
                </template>
              </Column>
              <Column field="sum_current" header="Объем за текущий месяц, л">
                <template #body="slotProps">
                  <div v-if="slotProps.data.row_number">
                    {{
                      slotProps.data.sum_current
                        ? slotProps.data.sum_current
                        : 0
                    }}
                  </div>
                  <div v-else>
                    {{ slotProps.data.sum_current_sum }}
                  </div>
                </template>
              </Column>
              <Column header="Последний платеж, Р">
                <template #body="slotProps">
                  <div v-if="slotProps.data.row_number">
                    {{
                      slotProps.data.last_payment
                        ? slotProps.data.last_payment.sum
                        : 0
                    }}
                  </div>
                  <div v-else v-tooltip.top="'Средний последний платеж'">
                    {{ slotProps.data.last_pay_avg }}
                  </div>
                </template>
              </Column>
              <Column header="Дата платежа">
                <template #body="slotProps">
                  <div v-if="slotProps.data.row_number">
                    {{
                      slotProps.data.last_payment
                        ? formaterDate(slotProps.data.last_payment.date)
                        : "-"
                    }}
                  </div>
                </template>
              </Column>
              <Column
                header="Счет"
                field="bill"
                style="width: 4rem; max-width: 4rem; justify-content: center"
              >
                <template #body="{ data }">
                  <div v-if="data.row_number">
                    <CreateBill
                      :idCompany="data.id"
                      :isDisabled="data.bill"
                      :companyName="data.name"
                      :btnClass="['p-1', 'p-button-rounded', 'x-btn-mm']"
                    >
                      <i v-tooltip="'Создать счет'" class="pi pi-file-edit"></i
                    ></CreateBill>
                  </div>
                </template>
              </Column>
              <Column field="inn" :sortable="true">
                <template #header>
                  <span style="order: 3">ИНН</span>
                  <FilterSearch
                    :type="input"
                    :dsc="false"
                    v-model="filters.inn"
                  />
                </template>
                <template #body="slotProps">
                  <div v-if="slotProps.data.row_number">
                    {{ slotProps.data.inn ? slotProps.data.inn : "-" }}
                  </div>
                </template>
              </Column>
              <div v-if="$store.state.curUser">
                <div v-if="$store.state.curUser.role === 1">
                  <Column field="the_reason" :sortable="true">
                    <template #header>
                      <span style="order: 3">Комментарий</span>
                      <FilterSearch
                        :type="input"
                        :dsc="false"
                        v-model="filters.comment"
                      />
                    </template>
                  </Column>
                  <Column field="manager" :sortable="true">
                    <template #header>
                      <span style="order: 3">Менеджер</span>
                      <FilterSearch
                        :type="input"
                        :dsc="false"
                        v-model="filters.manager"
                      />
                    </template>
                    <template #body="slotProps">
                      <div v-if="slotProps.data.row_number">
                        {{
                          slotProps.data.manager != null
                            ? slotProps.data.manager.user_name
                            : "-"
                        }}
                      </div>
                    </template>
                  </Column>
                  <Column header="Карты">
                    <template #body="slotProps">
                      <div v-if="slotProps.data.row_number">
                        <span
                          :class="{
                            'text-green-500': slotProps.data.card_acitve > 0,
                          }"
                          >{{ slotProps.data.card_acitve }}</span
                        >
                        <span class="mx-1">/</span>
                        <span
                          :class="{
                            'text-red-500': slotProps.data.card_block > 0,
                          }"
                        >
                          {{ slotProps.data.card_block }}</span
                        >
                      </div>
                    </template>
                  </Column>
                  <Column
                    header=""
                    style="
                      width: 4rem;
                      max-width: 4rem;
                      justify-content: center;
                    "
                  >
                    <template #body="{ data }">
                      <show-data-user :dataUser="data.user" />
                      <!-- <Button
                        label="Show"
                        icon="pi pi-external-link"
                        @click="visibleUserData = true"
                      />
                      <Dialog
                        v-model:visible="visibleUserData"
                        modal
                        header="Header"
                        :style="{ width: '50vw' }"
                      >
                        <ul
                          v-for="(user, idx) in data.user"
                          :key="idx"
                          class="list-none p-0 m-0"
                        >
                          <li
                            class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4"
                          >
                            <div class="flex">
                              <div class="mr-0 md:mr-8">
                                <span class="block text-900 font-medium mb-1">{{
                                  user.user_name
                                }}</span>
                              </div>
                            </div>
                            <div class="mt-2 md:mt-0 flex flex-nowrap">
                              <a :href="`tel:+${user.phone}`" class="mr-1">
                                {{ user.phone }}
                              </a>
                              <a :href="`mailto:${user.email}`">
                                {{ user.email }}
                              </a>
                            </div>
                          </li>
                        </ul>
                      </Dialog> -->
                    </template>
                  </Column>
                </div>
              </div>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateBill from "@/components/Home/CreateBill";
import TheSelectFilters from "../../../components/TheSelectFilters";
import SelectCompany from "../../../components/SelectCompany";
import store from "@/store";
import FilterSearch from "@/components/Transactions/Filtres/FilterSearch";
import FilterFromTo from "@/components/Transactions/Filtres/FilterFromTo";
import ShowDataUser from "../components/ShowDataUser.vue";

import { getBallanceList } from "../api/balances";
import { getBlnsReport } from "../api/reports";
import { timeFormater } from "@/handlers/timeFormater";
import TheFilterDate from "../../../components/TheFilterDate.vue";
export default {
  components: {
    CreateBill,
    TheSelectFilters,
    SelectCompany,
    FilterSearch,
    FilterFromTo,
    TheFilterDate,
    ShowDataUser,
  },
  name: "BalancesTable",
  data() {
    return {
      ballanceList: null,
      companies: null,
      loading: false,
      desc: true,
      sort: "",
      total: [],
      page: 1,
      rows: 10000,
      nameCompany: "",
      flagSearch: false,
      filters: {
        status: [],
        ballance: {
          from: null,
          to: null,
        },
        limit: {
          from: null,
          to: null,
        },
        contract_date: {
          from: null,
          to: null,
        },
        contract_num: null,
        comment: null,
        inn: null,
        manager: null,
      },
      sortView: true,
      visibleUserData: false,
    };
  },
  methods: {
    onPage(event) {
      this.page = event.page + 1;
      this.rows = event.rows;
      getBallanceList(this);
    },
    onSort(event) {
      console.log(event);
      this.sort = event.sortField;
      event.sortOrder == 1 ? (this.desc = true) : (this.desc = null);
      getBallanceList(this);
    },
    formaterDate(timeValue, hour) {
      return timeFormater(timeValue, hour);
    },
    stockClass(data) {
      return [
        {
          greenColor: data.status == 1,
          redColor: data.status == 2,
        },
        {
          "pi-check": data.status == 1,
          "pi-times": data.status == 2,
        },
      ];
    },
    rowClass(data) {
      if (data.status === 1 && +data.ballance < data.limit) {
        return "lowstock";
      }
      if (data.status === 2) {
        return "outofstock";
      }
      if (!data.status) {
        return !data.date ? "blns-result-row" : " ";
      }
      // return data.status === 1 && +data.ballance < data.limit ? "lowstock" : "outofstock";
      // return data.status === 0 ? "outofstock" : null;
    },

    choiceCompany(event, name) {
      let curCo;
      this.companies.forEach((el) => {
        el.name === name ? (curCo = el) : null;
      });
      store.commit("setCurCompany", curCo);
    },
    searchCompany() {
      this.flagSearch = true;
      getBallanceList(this);
    },
    iterateObject(obj) {
      for (let key in obj) {
        let value = obj[key];
        console.log(value, typeof value);
        if (typeof value == "object") {
          // работаем рекурсивно
          this.iterateObject(value);
        } else {
          obj[key] = null;
        }
      }
    },
    resetFiltres() {
      this.iterateObject(this.filters);
      (this.filters.status = []),
        (this.desc = true),
        (this.sort = ""),
        (this.sortView = false);
      this.nameCompany = "";
      setTimeout(() => {
        this.sortView = true;
      }, 300);
      // getBallanceList(this);
    },
    refreshData() {
      getBallanceList(this);
    },
    downloadExcel() {
      getBlnsReport(this);
    },
  },
  watch: {
    nameCompany(value) {
      if (value.length == 0) {
        getBallanceList(this);
      }
      //   if (value.length == 0 && this.flagSearch) {
      //     getBallanceList(this);
      //   }
    },
    filters: {
      deep: true,
      handler(data) {
        if (
          data.ballance.from !== null ||
          data.ballance.to !== null ||
          data.contract_num !== "" ||
          data.comment !== "" ||
          data.inn !== "" ||
          data.manager !== "" ||
          (data.contract_date.from !== null && data.contract_date.to !== null)
        ) {
          getBallanceList(this);
        }
      },
    },
  },
  // computed: {
  //   resetDisables() {
  //     return Object.keys(this.filters).every((el) => this.filters[el] != null);
  //   },
  // },
  mounted() {
    this.companies = store.state.company.companyList;
    getBallanceList(this);
  },
};
</script>

<style lang="scss">
.x-btn-mm {
  i {
    font-size: 0.7rem !important;
  }
}
</style>