<template>
  <layout-base>
    <loading-progress :loading="loading"></loading-progress>
    <MobileSpiner :loading="loading" />
    <BalancesTable /> 
  </layout-base>
</template>

<script >
import BalancesTable from "../modules/balances";

export default {
  components: {
    BalancesTable,
  },
  data() {
    return {
      loading: true,
    };
  },
  watch: {
    curCompanyList() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
  mounted() {
    this.loading = true;
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.loading = false;
    }, 1500);
  },
  created() {
    document.title = "Балансы | " + window.appBaseTitle;
  },
};
</script>

