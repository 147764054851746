import api from "@/api/index";
import {
  days
} from "@/plugins/dayjs/index";
import {
  setEmoji
} from "@/handlers/emoji";

export function getBallanceList(that) {
  let url = "ballance_v1/list";
  that.loading = true;
  that.ballanceList = [];
  api
    .get(url, {
      params: {
        contract_date_gte: that.filters.contract_date.from && that.filters.contract_date.to ?
          days(that.filters.contract_date.from, "server-start") :
          null,
        contract_date_lte: that.filters.contract_date.from && that.filters.contract_date.to ?
          days(that.filters.contract_date.to, "server-end") :
          null,
        name: that.nameCompany,
        rows: that.rows,
        page: that.page,
        company: that.companys,
        sort: that.sort,
        desc: that.desc,
        // description: that.filters.description
        //   ? that.filters.description.trim()
        //   : null,
        ballance_from: that.filters.ballance.from ?
          that.filters.ballance.from :
          null,
        ballance_to: that.filters.ballance.to ? that.filters.ballance.to : null,
        contract_num: that.filters.contract_num ?
          that.filters.contract_num :
          null,
        limit_from: that.filters.limit.from ? that.filters.limit.from : null,
        limit_to: that.filters.limit.to ? that.filters.limit.to : null,
        comment: that.filters.comment ? that.filters.comment : null,
        inn: that.filters.inn ? that.filters.inn : null,
        manager: that.filters.manager ? that.filters.manager : null,
        status: that.filters.status !== null ? that.filters.status : "",
      },
    })
    .then((response) => {
      if (response.data.result.length != 0) {
        that.totalRecords = response.data.total.count;
        that.ballanceList = [...response.data.result, ...[response.data.total]];
      } else {
        that.ballanceList = null;
      }
      console.info(
        `${response.statusText} ${response.status} ${setEmoji("access")}`
      );
    })
    .catch((err) => {
      console.log(err);
      console.log(err.request);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        case 406:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Укажите компании",
            life: 3000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      setTimeout(() => {
        that.loading = false;
        that.flagSearch = false;
      }, 2000);
    });
}