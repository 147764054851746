import api from "@/api/index";
import { setEmoji } from "@/handlers/emoji";
import { days } from "../plugins/dayjs/index";
import store from "@/store";

export function getBill(that, id) {
  api
    .get("payment/bill", {
      responseType: "blob",
      params: {
        sum: that.sum,
        company: id,
      },
    })
    .then((r) => {
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
      const href = URL.createObjectURL(r.data);
      const format = "pdf";
      const nameFile = `Счет на сумму ${that.sum} для ${
        store.state.company.curCompany.full_name
      } от ${days(new Date(), "date-hours")}.${format}`;
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", nameFile);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);

      that.visible = false;
      that.$toast.add({
        severity: "success",
        summary: `${setEmoji("access")} Бланк на оплату счета для компании ${
          that.companyName
        } успешно сформирован`,
        detail: `Сумма счета: ${that.sum} рублей`,
        life: 7000,
      });
      that.sum = 0;
    })
    .catch((err) => {
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    })
    .finally(() => {
      that.loading = false;
    });
}
