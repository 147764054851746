<template>
  <Button
    icon="pi pi-user"
    class="p-button-sm p-1 p-button-rounded special-btn"
    @click="visibleUserData = true"
    :disabled="!dataUser?.length"
  />
  <Dialog
    v-model:visible="visibleUserData"
    modal
    header="Список пользователей"
    :style="{ width: '50vw' }"
  >
    <ul v-for="(user, idx) in dataUser" :key="idx" class="list-none p-0 m-0">
      <li
        class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4"
      >
        <div class="flex">
          <div class="mr-0 md:mr-8">
            <span class="block text-900 font-medium mb-1">{{
              user.user_name
            }}</span>
          </div>
        </div>
        <div class="mt-2 md:mt-0 flex flex-nowrap">
          <span v-if="user.phone" class="mr-1">
            <a :href="`tel:+${user.phone}`" class="mr-1">
              <i class="pi pi-phone"></i> {{ user.phone }}
            </a>
          </span>
          <span v-if="user.email">
            <a :href="`mailto:${user.email}`">
              <i class="pi pi-envelope"></i> {{ user.email }}
            </a>
          </span>
        </div>
      </li>
    </ul>
  </Dialog>
</template>

<script>
export default {
  name: "ShowDataUser",
  props: {
    dataUser: {
      type: Array,
      requaried: true,
    },
  },
  data() {
    return {
      visibleUserData: false,
    };
  },
};
</script>

<style lang="scss">
.special-btn {
    width: 1.35rem !important;
    height: 1.35rem !important;
  i {
    font-size: 0.7 !important;
  }
}
</style>