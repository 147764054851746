<template>
  <div style="width: 16px" class="mr-1">
    <Button
      type="button"
      style="width: 16px; overflow: visible"
      icon="pi pi-filter"
      :class="{
        'fil-btn': modelValue.length != 0,
      }"
      class="p-button-sm p-button-text p-0"
      @click="toggle"
    />
    <OverlayPanel ref="filters" :showCloseIcon="true" ariaCloseLabel="">
      <div>
        <div v-for="item in items" :key="item.value" class="field-checkbox">
          <Checkbox
            :inputId="item.value"
            :name="item.name"
            :value="item.value"
            v-model="selected"
          />
          <label :for="item.value">{{ item.name }}</label>
        </div>
        <Button @click="showValue" label="Пременить" />
      </div>
    </OverlayPanel>
  </div>
</template>
  
  <script>
export default {
  name: "TheSelectFilters",
  props: {
    name: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selected: this.modelValue,
    };
  },
  methods: {
    isSearcDisabled() {
      if (this.selected) {
        this.isSearcDisables = false;
      } else {
        this.isSearcDisables = true;
        this.$emit("update:modelValue", null);
      }
    },
    showValue() {
      this.$emit("update:modelValue", this.selected);
      this.toggle();
    },
    toggle(event) {
      this.$refs.filters.toggle(event);
    },
  },
  created() {
    this.selected
      ? (this.isSearcDisables = false)
      : (this.isSearcDisables = true);
  },
  computed: {
    //   modelValue: {
    //   get () { return this.modelValue },
    //   set (value) { this.$emit('update:modelValue', value) },
    // }
  },
  watch: {},
};
</script>
  
  <style lang="scss">
</style>